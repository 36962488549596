import React from "react";

import "./Header.css";

export default function Header(props) {
  return (
    <div className="_header header-wrapper">
      <div className="_header page-container flex">
        <div className="header-logo hover" />
        <div className="header-option hover ">HOME</div>
        <div className="header-option hover">ABOUT</div>
      </div>
    </div>
  );
}
