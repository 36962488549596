import React from "react";

import { Splash, ComingSoon } from "./Home";

import "./HomePage.css";

export default function HomePage(props) {
  return (
    <div className="_home page-wrapper">
      <div className="_home page-container">
        <Splash />
        <ComingSoon />
      </div>
    </div>
  );
}
