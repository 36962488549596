import React, { useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";

import { HomePage } from "./components";
import { Header } from "./organisms/Header";

import "./App.css";

export default function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/*" element={<NoMatch />} />
      </Routes>
    </>
  );
}

function NoMatch() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/", { replace: true });
  });
  return null;
}
