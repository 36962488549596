import React from "react";

import "./Splash.css";

export default function Splash(props) {
  return (
    <div>
      <div className="splash flex">
        <div className="splash-title glitch layers" data-text="OF SOUND MIND">
          <span>OF SOUND MIND</span>
        </div>
      </div>
    </div>
  );
}
