import React from "react";

import "./ComingSoon.css";

export default function ComingSoon(props) {
  return (
    <div>
      <div className="coming-soon">
        <div className="coming-soon-background" />
        <div className="coming-soon-background2" />
        <div className="coming-soon-content flex">
          <div
            className="coming-soon-title _2 glitch layers"
            data-text="TRUST ME, YOU WILL BE HERE SOON"
          >
            <span>YOU WILL BE HERE SOON</span>
          </div>
        </div>
      </div>
    </div>
  );
}
